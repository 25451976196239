// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore/lite';

// Your web app's Firebase configuration
const FBConfigWebsite = {
    apiKey: 'AIzaSyACs0XwMi5kpZhZc017InH1uFBuMrYkyZc',
    authDomain: 'bazarde-website.firebaseapp.com',
    projectId: 'bazarde-website',
    storageBucket: 'bazarde-website.appspot.com',
    messagingSenderId: '434982935216',
    appId: '1:434982935216:web:33983ab7f96967caa33975',
};

export const websiteApp = initializeApp(FBConfigWebsite, 'Website');
export const authWebsite = getAuth(websiteApp);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const FBConfigMobileApplication = {
    apiKey: 'AIzaSyD-iSyj87PPAgXj0muggqZ-X1uNjunUiMI',
    authDomain: 'bazarde-production.firebaseapp.com',
    databaseURL:
        'https://bazarde-production-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'bazarde-production',
    storageBucket: 'bazarde-production.appspot.com',
    messagingSenderId: '773708456674',
    appId: '1:773708456674:web:cd882a26e484e721ca74bb',
    measurementId: 'G-8QYX8GF8EG',
};

// Initialize Firebase
export const mobileApp = initializeApp(FBConfigMobileApplication, 'Mobile');
export const mobileDb = getFirestore(mobileApp);
export const authMobile = getAuth(mobileApp);
