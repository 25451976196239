import React, { useEffect, useState } from 'react';
import './index.scss';

// Packages
import uniqid from 'uniqid';

// Components
import HeaderComponent from '../../Components/Header';

// Api
import { CheckAuth } from '../../Api/auth';
import { PutCode } from '../../Api/code';

const CodeScreen = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [auth, setAuth] = useState<boolean>(false);

    const [code, setCode] = useState<string>('');
    const [typeOfCode, setTypeOfCode] = useState<string>('');
    const [date, setDate] = useState<number>(0);

    const [error, setError] = useState<string>('');

    const SendCode = async () => {
        setError('');

        try {
            if (!code.length || !typeOfCode.length || !date) {
                return;
            }

            await PutCode(typeOfCode, {
                code: code.toUpperCase(),
                lst: Date.now() - 2 * 60 * 60 * 1000,
                end: date,
            });

            setError(': ok');
        } catch (error) {
            console.log(error);
        }

        console.log('pass here');
    };

    const GenerateCode = () => {
        const codeGenerated = uniqid().slice(-6).toUpperCase();
        setCode(codeGenerated);
    };

    const AddTypeToCode = (type: string, db: string) => {
        if (code.includes('_')) {
            setCode((prev) => prev.replace(prev.slice(0, 4), type));
        } else {
            setCode((prev) => type + prev);
        }

        setTypeOfCode(db);
    };

    if (!auth) {
        return (
            <>
                <HeaderComponent />

                <div className="container-code">
                    <h1>Authentification</h1>
                    <input
                        type="email"
                        className="input ipt-full"
                        placeholder="Email"
                        onChange={(v) => setEmail(v.target.value)}
                    />
                    <input
                        type="password"
                        className="input ipt-full"
                        placeholder="Mot de passe"
                        onChange={(v) => setPassword(v.target.value)}
                    />

                    <button
                        className="input ipt-submit"
                        onClick={async () => {
                            try {
                                await CheckAuth(email, password);

                                setAuth(true);
                            } catch (error: any) {
                                setAuth(false);
                                console.log(error);
                            }
                        }}
                    >
                        CHECK
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            <HeaderComponent />

            <div className="container-code">
                <h1>Créer un code</h1>

                <div>
                    <input
                        type="text"
                        className="input ipt-code"
                        placeholder="Code ?"
                        value={code.toUpperCase()}
                        onChange={(v) => setCode(v.target.value)}
                    />
                    <button
                        className="input ipt-generate"
                        onClick={GenerateCode}
                    >
                        Generate
                    </button>
                </div>

                <div className="container-type">
                    <label htmlFor="demo" className="input ipt-radio">
                        <input
                            type="radio"
                            name="typeOfCode"
                            id="demo"
                            className="input ipt-type"
                            // checked
                            onChange={() => AddTypeToCode('DMO_', 'demos')}
                        />
                        DEMO
                    </label>

                    <label htmlFor="partner" className="input ipt-radio">
                        <input
                            type="radio"
                            name="typeOfCode"
                            id="partner"
                            className="input ipt-type"
                            onChange={() => AddTypeToCode('PTR_', 'partners')}
                        />
                        PARTNER
                    </label>

                    <label htmlFor="long" className="input ipt-radio">
                        <input
                            type="radio"
                            name="typeOfCode"
                            id="long"
                            className="input ipt-type"
                            onChange={() => AddTypeToCode('LNG_', 'longs')}
                        />
                        LONG DURATION
                    </label>

                    <label htmlFor="purchase" className="input ipt-radio">
                        <input
                            type="radio"
                            name="typeOfCode"
                            id="purchase"
                            className="input ipt-type"
                            onChange={() => AddTypeToCode('', 'purchases')}
                        />
                        PURCHASE
                    </label>
                </div>

                <input
                    type="datetime-local"
                    className="input ipt-date"
                    onChange={(v) =>
                        setDate(new Date(v.target.value).getTime())
                    }
                />

                <button onClick={SendCode} className="input ipt-submit">
                    Envoyer {error}
                </button>
            </div>
        </>
    );
};

export default CodeScreen;
