import React, { useEffect } from 'react';
import './index.scss';

// Packages
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Screens
import HomeScreen from '../Pages/Home';
import TermsScreen from '../Pages/Terms';
import PrivacyPolicyScreen from '../Pages/PrivacyPolicy';
import DetectScreen from '../Pages/Detect';
import CodeScreen from '../Pages/Code';
import PartnerScreen from '../Pages/Partner';

// Components
import FooterComponent from '../Components/Footer';

// Hooks
import { useTracking } from '../Hooks/useTracking';

const Navigation = () => {
    useTracking();

    return (
        <div>
            <div className="container-body">
                <Switch>
                    <Route exact path="/" component={HomeScreen} />
                    <Route exact path="/terms" component={TermsScreen} />
                    <Route exact path="/code" component={CodeScreen} />
                    <Route
                        exact
                        path="/partenariat"
                        component={PartnerScreen}
                    />
                    <Route
                        exact
                        path="/privacy-policy"
                        component={PrivacyPolicyScreen}
                    />
                    <Route
                        exact
                        path="/telechargement"
                        component={DetectScreen}
                    />
                </Switch>
            </div>

            <FooterComponent />
        </div>
    );
};

export default Navigation;
