import React from 'react';
import './index.scss';

// Hooks
import usePageTracking from '../../Hooks/usePageTracking';

// Components
import HeaderComponent from '../../Components/Header';

const HomeScreen = () => {
    usePageTracking();
    return (
        <>
            <HeaderComponent />

            <div className="container-policy">
                <h1>Privacy Policy</h1>
                <h2>AIM OF THE PRIVACY POLICY</h2>
                <p>
                    The Privacy Policy represents an ongoing concern for BAZARDE
                    and companies affiliated to it (mother company,
                    subsidiaries, etc.) (hereinafter referred to as BAZARDE) in
                    ensuring the protection of the private lives of users of the
                    services described hereinafter in view of current
                    regulations and in particular, Law 78-17 of January 6, 1978
                    relating to information technology, files and freedom.{' '}
                    <br />
                    The current Privacy Policy is an integral part of the Terms
                    and Conditions of Use governing the games and software
                    applications provided by BAZARDE, in particular via
                    third-party social network platforms (hereinafter referred
                    to as “Platforms”), including all content, all services and
                    all features accessed via these games and software
                    applications (hereinafter referred to collectively as
                    “Services”).
                    <br />
                    In particular, the current Privacy Policy aims to inform you
                    of the general policy implemented by BAZARDE in terms of
                    protecting personal data collected and processed as a result
                    of using the Services governed by the aforementioned Terms
                    and Conditions of Use.
                    <br />
                    By accessing and/or using the Services, you explicitly
                    accept the terms of this Privacy Policy and, as a
                    consequence, agree to the gathering, retaining, using and
                    divulging of your personal data, as described herein.
                    <br />
                    You are hereby advised that the Privacy Policy can be
                    modified by BAZARDE at any time. With this in mind, by
                    continuing to use and/or access the Services after the
                    last-modified date of any changes, you will be deemed to
                    have accepted these changes as modified. You are therefore
                    invited to regularly consult the Privacy Policy to ensure
                    you are up-to-date with any potential changes.
                </p>
                <h2>DATA COLLECTION </h2>
                <h3>Data collected by BAZARDE via the Platforms</h3>
                <p>
                    The data collected and processed are willingly supplied by
                    you to BAZARDE through the Platforms by agreeing to access
                    and use the Services from these Platforms. At a minimum,
                    such data will include your name, profile photo, sex, your
                    network(s), username, the user ID numbers of your friends
                    and/or people belonging to your network(s) and other
                    information supplied by you to all users of the Platforms.
                    If applicable, BAZARDE is also likely to collect, still via
                    the Platforms, any additional data you may agree to supply
                    to it (e.g., your email address). As all personal data are
                    supplied directly to BAZARDE by the Platforms, it is deemed
                    that you have read and agreed to the Platforms’ terms and
                    conditions of use and their privacy policy. BAZARDE shall
                    not be held liable for the way in which Platforms act in
                    terms of collecting, storing and processing your personal
                    data. In addition, you are responsible for the privacy
                    settings of your accounts on the Platforms in such a way
                    that BAZARDE cannot be held liable in this regard.
                </p>

                <h3>Data collected by BAZARDE directly</h3>
                <p>
                    Through provision of its Services, BAZARDE may also collect
                    data from you directly. As such, by using certain features
                    within the Services which require online payment, you agree
                    that your bank details, email address and/or your telephone
                    number may be collected in compliance with current
                    regulations in force. Within the context of using its
                    Services and/or related official spaces (dedicated official
                    spaces on the Platforms, support website, etc.), BAZARDE may
                    also invite you to participate in promotional events
                    (contests, free sweepstakes, etc.). Participation in these
                    promotional events implies your full and complete agreement
                    to the collection, storage and processing of any personal
                    data you supply. If applicable, and within the context of
                    using the Services, the number of points you collect may be
                    retained in order to determine your rank/level with the
                    particular purpose of defining player rankings. By using the
                    Services, you agree, in particular, that your score, your
                    rank/level, your ID and your photo be listed in the overall
                    player ranking, which all users of the Services will be able
                    to view. Furthermore, when you contact BAZARDE directly via
                    its support service, the latter may collect and store your
                    contact data (in particular your name, mailing address and
                    email address) and, where applicable, your user ID number
                    for the Platforms from where you access and use the
                    Services, as well as some information relating to your use
                    of the Services that you will provide to BAZARDE. Finally,
                    BAZARDE may need to collect aggregate data in order to be
                    able to provide an optimized level of Services. This may
                    include data such as your IP address, browser type, browser
                    language, number of clicks, pages viewed and also, more
                    generally, browsing and connection data.
                </p>

                <h2>PURPOSES OF COLLECTING AND PROCESSING YOUR DATA</h2>
                <p>
                    BAZARDE collects, stores and processes your personal data
                    with the aim of enabling you to access and use its Services
                    effectively. Data collected from the Platforms and/or
                    directly from you enable in particular the better
                    administration and improvement of the Services,
                    communication between users of the Services, the supply of
                    technical support and efficient responses to user requests,
                    proper application of the Terms and Conditions of Use, which
                    govern BAZARDE Services, communication about new and current
                    Services along with promotional offers, the analysis of user
                    traffic trends and the collection of statistical data or
                    even the targeting of advertising content that may appear on
                    our Services. When you receive promotional communications on
                    behalf of BAZARDE, you will have the opportunity to put and
                    end to them by following the unsubscribe instructions
                    contained in the communication itself.
                </p>

                <h2>RECIPIENTS OF YOUR DATA</h2>

                <p>
                    Your data will not be provided to third parties, except in
                    the following cases: <br />
                    <br />
                    - when BAZARDE must share your data with commercial
                    partners, suppliers, service providers and in particular,
                    technical service providers, resellers and buyers, with the
                    aim of providing Services, resolving functionality problems
                    of Services, communicating with users of the Services or
                    with a view to providing advertising and promotional
                    information related to Services;
                    <br />
                    <br />
                    - in particular, BAZARDE supplies your data to third parties
                    that provide services on their behalf, including payment
                    processing, data analysis, email campaign processing, the
                    hosting of Services and the processing of certain marketing
                    activities for its Services;
                    <br />
                    <br />- BAZARDE may communicate your data in applying a law,
                    regulation or in accordance with a decision from an
                    appropriate regulating or legal authority or, if it proves
                    necessary, with the aim of safeguarding its rights and
                    interests;
                    <br />
                    <br />- BAZARDE will be authorized to transfer your data in
                    compliance with article 10 of the present regulations in the
                    event of a change of control of BAZARDE or a cessation of
                    all or part of its activities.
                    <br />
                    <br />- when you have given prior agreement to the sharing
                    and/or divulging of your data, including by features offered
                    on the Platforms;
                    <br />
                    <br />
                    We would like to draw your attention in particular to the
                    fact that by using certain BAZARDE Services via the
                    Platforms, other users of these Services will be able to
                    view your user profile for these Platforms. This profile may
                    enable other users to obtain information associated with
                    this profile made public by you. BAZARDE cannot be held
                    liable in this regard. The settings for information
                    associated with your profile are your responsibility.
                    BAZARDE does not share your personal data with third-party
                    advertisers for marketing purposes unless you have provided
                    prior consent for it. Nevertheless, it should be noted that
                    advertisers may obtain personal data which relates to you
                    from your user profile on the Platforms or from other public
                    sources. In the same way, BAZARDE shall not be held
                    responsible in this regard. On the other hand, BAZARDE may
                    use and share certain non-personal technical or aggregate
                    data relating to the browsing of Services users with certain
                    third parties. Please refer to article 7 for a few examples
                    of browsing data. BAZARDE may allow advertisers to collect
                    this type of technical or aggregate data using certain
                    technology such as cookies and to do so in order to be able
                    to offer targeted advertising within the more general
                    context of personalizing your user experience. The
                    gathering, processing and divulging of such anonymous data
                    shall not be subject to the restrictions cited in the
                    current privacy policy.
                </p>

                <h2>SECURITY OF YOUR DATA</h2>
                <p>
                    BAZARDE does its absolute best to protect your personal data
                    and therefore to limit the risk of any loss, deterioration
                    or incorrect use of it. As such, when you enter sensitive
                    information such as a credit card number or password while
                    using our Services, we encode said information using the
                    “SSL” cryptographic protocol (for more information about
                    SSL, go to
                    http://en.wikipedia.org/wiki/Secure_Sockets_Layer). In spite
                    of the security measures undertaken, BAZARDE shall not
                    guarantee the transporting and integrity of data transmitted
                    to third parties through the Services (for example, when
                    buying Physical and Virtual Products) and cannot be held
                    liable in this regard. You accept in particular the limits
                    and risks inherent to using the Internet and you promise as
                    of now to take all preventive measures available to you to
                    safeguard the privacy of your personal data (including any
                    potential account passwords required to use the Services).
                </p>

                <h2>SAFEGUARDING OF YOUR DATA</h2>
                <p>
                    Your personal data is stored with the host identified in the
                    Legal Section and are held for the period of time necessary
                    for carrying out the aforementioned purposes. Beyond this
                    period of time, they will be kept exclusively for
                    statistical purposes and will not be used in any other way
                    whatsoever.
                </p>

                <h2>COOKIES</h2>
                <p>
                    Within the context of facilitating your access and use of
                    the Services, of personalizing this access and use and also
                    of enabling us to manage the advertising which may be viewed
                    from our Services, you are advised that BAZARDE is likely to
                    apply an automatic tracking procedure (cookie). A cookie
                    does not enable you to be identified. On the other hand, it
                    records information relating to your computer’s browsing of
                    sites from where you have accessed the Services and other
                    associated areas (pages you have viewed, the date and time
                    of viewing, etc.) that we will be able to read during your
                    subsequent visits. However, you can opt to turn off
                    “Cookies” by configuring your browser according to the
                    instructions indicated on the Wikihow website
                    here:http://www.wikihow.com/Clear-Your-Browser’s-Cookies.
                    Please note that configuring your browser in this way may
                    change the quality of the Services.
                </p>

                <h2>THIRD-PARTY PRIVACY POLICY</h2>
                <p>
                    The Services may contain links to third-party websites.
                    Likewise, certain Services content (advertising related or
                    otherwise) may be hosted with third parties. Finally, within
                    the context of certain Services features requiring online
                    payment, you may be asked to use secure payment tools
                    provided by third-party companies. <br />
                    BAZARDE cannot be held liable for practices relating to the
                    collection, storage and processing of personal data by these
                    third parties and/or the content of their websites. You
                    warrant BAZARDE against any complaint, action and procedure
                    initiated in this regard. If you have any questions about
                    the use of your personal data by these third parties, you
                    should refer to their applicable privacy policies and
                    contact the third-party companies directly.
                </p>

                <h2>TRANSFER OF DATA INTERNATIONALLY</h2>
                <p>
                    For the purposes of the Services’ functioning, personal data
                    collected by BAZARDE are hosted in the USA. BAZARDE and its
                    host respect the SafeHarbor framework as cited by the
                    Department of Trade concerning the collection, use and
                    retention of online user data from the European Union.
                    BAZARDE takes all reasonable measures to ensure that your
                    data are processed in complete security and in compliance
                    with this Privacy Policy. By submitting your data to BAZARDE
                    and, more generally, by accessing and using the Services,
                    you agree to the transfer, storage and/or processing of your
                    data in the USA.
                </p>

                <h2>CESSATION / TRANSFER</h2>

                <p>
                    BAZARDE reserves the right to transfer your personal data in
                    the event of a change of control or the cessation of all or
                    part of its activities, in particular through acquisition or
                    merger with another company. In such a case, BAZARDE will
                    use all means to warn users of any such transfer and if
                    applicable, any change affecting the Privacy Policy.
                </p>

                <h2>PRIVACY POLICY CONCERNING CHILDREN UNDER 13</h2>
                <p>
                    The Services offered by BAZARDE are not intended for
                    children under 13. Anyone under 13 should not use the
                    Services. With this in mind, BAZARDE does not willingly
                    collect personal data from children under 13. All the same,
                    if the collection of such data is brought to the attention
                    of BAZARDE, we will take all useful measures possible to
                    delete the corresponding data.
                </p>

                <h2>
                    RIGHT OF ACCESS, MODIFICATION AND DELETION OF YOUR PERSONAL
                    DATA
                </h2>

                <p>
                    In compliance with legal provisions, you have a general
                    right to access, edit and delete your personal data which
                    have been collected pursuant to your access to and/or use of
                    the Services. Regarding data collected via the Platforms in
                    particular, you can access and edit such data directly from
                    the personal area available to you on these Platforms. If
                    you have no personal area, you should refer to the
                    Platforms’ access and editing policy.
                </p>

                <h2>ANY QUESTIONS?</h2>
                <p>
                    If you have any questions about BAZARDE Services or Privacy
                    Policy, feel free to contact us via email at
                    bazarde.app@gmail.com
                </p>
            </div>
        </>
    );
};

export default HomeScreen;
