import React from 'react';
import './index.scss';

// Packages
import { Link } from 'react-router-dom';

// Images
import LogoSvg from '../../Assets/logo.svg';

const HeaderComponent = () => {
    return (
        <div className="container-header">
            <Link to="/">
                <img
                    src={LogoSvg}
                    alt="Logo de l'application Bazarde"
                    className="logo-header"
                />
            </Link>
        </div>
    );
};

export default HeaderComponent;
