import React, { useEffect, useState } from 'react';
import './index.scss';

// Packages
import { useLocation } from 'react-router-dom';

// Components
import HeaderComponent from '../../Components/Header';

// Utils
import { getMobileOperatingSystem } from '../../Utils/DetectStore';

const PartnerScreen = () => {
    let query: any = new URLSearchParams(useLocation().search);

    const [code, setCode] = useState<string>('');

    useEffect(() => {
        const codeOfQuery = query.get('code');

        if (!codeOfQuery || !codeOfQuery.length) {
            Redirect();
        }

        setCode(codeOfQuery);
    }, []);

    const Redirect = () => {
        const os = getMobileOperatingSystem();

        if (os === 'iOS') {
            window.location.href =
                'https://apps.apple.com/us/app/bazarde/id1566451825';
        } else if (os === 'Android') {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.algorithmx.bazarde';
        }
    };

    return (
        <>
            <HeaderComponent />
            <div className="container-partner">
                <button onClick={() => Redirect()}>{code}</button>
                <h3>
                    Clique sur le code pour
                    <br />
                    jouer gratuitement
                </h3>
            </div>
        </>
    );
};

export default PartnerScreen;
