import React from 'react';
import './index.scss';

// Hooks
import usePageTracking from '../../Hooks/usePageTracking';

// Components
import HeaderComponent from '../../Components/Header';

const HomeScreen = () => {
    usePageTracking();

    return (
        <>
            <HeaderComponent />
            <div className="container-terms">
                <h1>Terms of Use</h1>
                <p>
                    ​ You can subscribe for this app to get access to premium
                    features and unlimited content.
                </p>
                <p>
                    Subscription options can be: 1-week
                    <br />
                    <br />
                    All prices are subject to change without notification. We
                    occasionally run promotion prices as incentives or limited
                    time opportunities for qualifying purchases made during the
                    promotional time period. Due to the time sensitive and
                    promotional nature of these events, we are unable to offer
                    price protection or retroactive discounts or refunds for
                    previous purchases in the event of a price reduction or
                    promotional offering.
                    <br />
                    <br />
                    Payment will be charged to iTunes Account at confirmation of
                    purchase.
                </p>
                <p>
                    Subscription automatically renews unless auto-renew is
                    turned off at least 24-hours before the end of the current
                    period.
                    <br />
                    Account will be charged for renewal within 24-hours prior to
                    the end of the current period at the cost of the chosen
                    package.
                    <br />
                    Subscriptions may be managed by the user and auto-renewal
                    may be turned off by going to the user's iTunes Account
                    Settings after purchase.
                    <br />
                    No cancellation of the current subscription is allowed
                    during active subscription period.
                </p>
                <p>
                    You may cancel a subscription during its free trial period
                    via the subscription setting through your iTunes account.
                    This must be done 24 hours before the end of the
                    subscription period to avoid being charged. Please visit
                    https://support.apple.com/en-us/HT202039 for more
                    information.
                    <br />
                    <br />
                    You may turn off the auto-renewal of your subscription via
                    your iTunes Account Settings. However, you are not able to
                    cancel the current subscription during its active period.
                    <br />
                    <br />
                    Any unused portion of a free trial period, if offered, will
                    be forfeited when the user purchases a subscription to that
                    publication, where applicable.
                </p>
            </div>
        </>
    );
};

export default HomeScreen;
