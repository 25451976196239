import { setDoc, doc } from 'firebase/firestore/lite';
import { mobileDb } from './constants';

interface Params {
    code: string;
    lst: string | number;
    end: string | number;
}

export const PutCode = async (type = 'purchases', params: Params) =>
    await setDoc(doc(mobileDb, type, params.code), {
        lst: Number(params.lst),
        end: Number(params.end),
    });
