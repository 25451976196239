import React from 'react';
import ReactDOM from 'react-dom';
import './Themes/_reset.scss';

// Packages
import { BrowserRouter as Router } from 'react-router-dom';

// Entry
import Navigations from './Navigations';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Navigations />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
