import React from 'react';
import './index.scss';

// Packages
import { Link } from 'react-router-dom';

const FooterComponent = () => {
    return (
        <div className="container-footer">
            <div className="footer-nav">
                <Link to="/privacy-policy" className="footer-link">
                    Privacy policy
                </Link>
                <span className="footer-link copyright">© 2021 Bazarde</span>
                <Link to="/terms" className="footer-link">
                    Terms of Use
                </Link>
            </div>
        </div>
    );
};

export default FooterComponent;
