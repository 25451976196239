import React from 'react';
import './index.scss';

// Packages
import ReactGA from 'react-ga';

// Hooks
import usePageTracking from '../../Hooks/usePageTracking';

// Images
import LogoImg from '../../Assets/logo.svg';
import BadgeApple from '../../Assets/badge-apple.svg';
import BadgeAndroid from '../../Assets/badge-android.svg';

const HomeScreen = () => {
    usePageTracking();

    return (
        <div className="container-home">
            <img src={LogoImg} alt="Logo Bazarde" className="logo-home" />
            <h1 className="subTitle">
                Le seul jeu qui veut se <br /> débarrasser de ses joueurs
            </h1>

            <div className="container-badges">
                <a
                    href="https://apps.apple.com/us/app/bazarde/id1566451825"
                    onClick={() => {
                        ReactGA.event({
                            category: 'Apple Store badge',
                            action: 'User click on Apple Store badge.',
                        });
                    }}
                >
                    <img
                        src={BadgeApple}
                        alt="Télécharger sur Apple Store"
                        className="badge"
                    />
                </a>

                <a
                    href="https://play.google.com/store/apps/details?id=com.algorithmx.bazarde"
                    onClick={() => {
                        ReactGA.event({
                            category: 'Google Play Store badge',
                            action: 'User click on Google Play Store badge.',
                        });
                    }}
                >
                    <img
                        src={BadgeAndroid}
                        alt="Télécharger sur Google Play Store"
                        className="badge"
                    />
                </a>
            </div>
        </div>
    );
};

export default HomeScreen;
