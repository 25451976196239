import React, { useEffect } from 'react';
import './index.scss';

// Hooks
import usePageTracking from '../../Hooks/usePageTracking';

// Components
import HeaderComponent from '../../Components/Header';

// Utils
import { getMobileOperatingSystem } from '../../Utils/DetectStore';

const DetectScreen = () => {
    usePageTracking();

    useEffect(() => {
        const os = getMobileOperatingSystem();

        if (os === 'iOS') {
            window.location.href =
                'https://apps.apple.com/us/app/bazarde/id1566451825';
        } else if (os === 'Android') {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.algorithmx.bazarde';
        }
    }, []);

    return (
        <>
            <HeaderComponent />
            <div className="container-terms">
                <h1>Redirection vers ton store</h1>
            </div>
        </>
    );
};

export default DetectScreen;
